const countries = [
  'bouvet-island',
  'colombia',
  'comoros',
  'congo-brazzaville',
  'congo-kinshasa',
  'cook-islands',
  'costa-rica',
  'cote-divoire',
  'croatia',
  'cuba',
  'curacao',
  'cyprus',
  'czechia',
  'denmark',
  'djibouti',
  'dominica',
  'dominican-republic',
  'ecuador',
  'egypt',
  'el-salvador',
  'equatorial-guinea',
  'eritrea',
  'eswatini',
  'ethiopia',
  'falkland-islands',
  'faroe-islands',
  'fiji',
  'finland',
  'france',
  'french-guiana',
  'french-polynesia',
  'aruba',
  'french-southern-territories',
  'gabon',
  'gambia',
  'georgia',
  'germany',
  'ghana',
  'gibraltar',
  'greece',
  'greenland',
  'grenada',
  'guadeloupe',
  'guam',
  'guatemala',
  'guernsey',
  'guinea',
  'guinea-bissau',
  'guyana',
  'haiti',
  'heard-and-mcdonald-islands',
  'vatican-city',
  'honduras',
  'hong-kong-sar-china',
  'hungary',
  'iceland',
  'india',
  'indonesia',
  'iran',
  'iraq',
  'ireland',
  'isle-of-man',
  'israel',
  'italy',
  'jamaica',
  'japan',
  'jersey',
  'jordan',
  'kazakhstan',
  'kenya',
  'kiribati',
  'north-korea',
  'south-korea',
  'kuwait',
  'kyrgyzstan',
  'laos',
  'latvia',
  'lebanon',
  'lesotho',
  'liberia',
  'libya',
  'liechtenstein',
  'lithuania',
  'luxembourg',
  'macao-sar-china',
  'madagascar',
  'malawi',
  'malaysia',
  'maldives',
  'mali',
  'malta',
  'marshall-islands',
  'martinique',
  'mauritania',
  'mauritius',
  'bhutan',
  'mayotte',
  'mexico',
  'micronesia',
  'moldova',
  'monaco',
  'mongolia',
  'montenegro',
  'montserrat',
  'morocco',
  'mozambique',
  'myanmar-burma',
  'namibia',
  'nauru',
  'nepal',
  'netherlands',
  'new-caledonia',
  'new-zealand',
  'nicaragua',
  'niger',
  'nigeria',
  'niue',
  'norfolk-island',
  'north-macedonia',
  'northern-mariana-islands',
  'norway',
  'oman',
  'pakistan',
  'palau',
  'palestinian-territories',
  'panama',
  'anguilla',
  'australia',
  'papua-new-guinea',
  'paraguay',
  'peru',
  'philippines',
  'pitcairn-islands',
  'poland',
  'portugal',
  'puerto-rico',
  'qatar',
  'romania',
  'russia',
  'rwanda',
  'st-barthelemy',
  'st-helena',
  'st-kitts-and-nevis',
  'st-lucia',
  'st-martin',
  'st-pierre-and-miquelon',
  'st-vincent-and-grenadines',
  'samoa',
  'san-marino',
  'sao-tome-and-principe',
  'saudi-arabia',
  'senegal',
  'serbia',
  'seychelles',
  'sierra-leone',
  'singapore',
  'sint-maarten',
  'slovakia',
  'slovenia',
  'solomon-islands',
  'somalia',
  'south-africa',
  'syria',
  'taiwan',
  'tajikistan',
  'tanzania',
  'thailand',
  'timor-leste',
  'togo',
  'tonga',
  'trinidad-and-tobago',
  'us-virgin-islands',
  'wallis-and-futuna',
  'western-sahara',
  'austria',
  'azerbaijan',
  'bahamas',
  'bahrain',
  'bangladesh',
  'barbados',
  'belarus',
  'belgium',
  'belize',
  'benin',
  'bermuda',
  'bolivia',
  'bosnia-and-herzegovina',
  'afghanistan',
  'aland-islands',
  'albania',
  'algeria',
  'american-samoa',
  'andorra',
  'angola',
  'antarctica',
  'antigua-and-barbuda',
  'argentina',
  'caribbean-netherlands',
  'chad',
  'estonia',
  'canada',
  'cocos-keeling-islands',
  'cayman-islands',
  'central-african-republic',
  'chile',
  'china',
  'svalbard-and-jan-mayen',
  'armenia',
  'botswana',
  'brazil',
  'british-indian-ocean-territory',
  'brunei',
  'bulgaria',
  'burkina-faso',
  'burundi',
  'christmas-island',
  'sweden',
  'switzerland',
  'tokelau',
  'cape-verde',
  'cambodia',
  'cameroon',
  'ukraine',
  'united-arab-emirates',
  'united-kingdom',
  'united-states',
  'us-outlying-islands',
  'uruguay',
  'uzbekistan',
  'vanuatu',
  'venezuela',
  'vietnam',
  'british-virgin-islands',
  'kosovo',
  'reunion',
  'south-georgia-and-south-sandwich-islands',
  'south-sudan',
  'spain',
  'sri-lanka',
  'sudan',
  'suriname',
  'tunisia',
  'turkey',
  'turkmenistan',
  'turks-and-caicos-islands',
  'tuvalu',
  'uganda',
  'yemen',
  'zambia',
  'zimbabwe',
]

let countriesSet = new Set(countries)

/** @type {import('@sveltejs/kit').ParamMatcher} */
export function match(param) {
  return countriesSet.has(param)
  // return /^(.+)-(vaccinated|unvaccinated)$/.test(param)
}
