/* eslint-disable @typescript-eslint/ban-ts-comment */
import { BrowserTracing } from '@sentry/tracing'
import type { HandleClientError } from '@sveltejs/kit'
import config from './config'

import {
  Breadcrumbs,
  BrowserClient,
  Dedupe,
  defaultStackParser,
  getCurrentHub,
  GlobalHandlers,
  LinkedErrors,
  makeFetchTransport,
} from '@sentry/browser'
import { Replay } from '@sentry/replay'
import { reportError } from './utils/track'

const client = new BrowserClient({
  dsn: config.sentryDSN,
  enabled: import.meta.env.PROD,

  transport: makeFetchTransport,
  stackParser: defaultStackParser,

  integrations: [
    new Breadcrumbs(),
    new GlobalHandlers(),
    new LinkedErrors(),
    new Dedupe(),
    new BrowserTracing(),
    new Replay({
      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      sessionSampleRate: window?.location?.pathname?.includes('stays') ? 1.0 : 0.2,

      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      errorSampleRate: 1.0,

      // Mask all text content with asterisks (*). Passes text
      // content through to `maskTextFn` before sending to server.
      //
      // Defaults to true, uncomment to change
      maskAllText: false,

      // Block all media elements (img, svg, video, object,
      // picture, embed, map, audio)
      //
      // Defaults to true, uncomment to change
      blockAllMedia: false,

      stickySession: true,
      useCompression: true,
      hooks: {},
    }),
  ],

  // To set a uniform sample rate
  tracesSampleRate: 1,

  ignoreErrors: [/Failed to fetch dynamically imported module/],

  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
  ],
})

getCurrentHub().bindClient(client)

// Sentry.init({
//   dsn: config.sentryDSN,
//   enabled: import.meta.env.PROD,

//   // This enables automatic instrumentation (highly recommended), but is not
//   // necessary for purely manual usage

//   // To set a uniform sample rate
//   tracesSampleRate: 1,

//   denyUrls: [
//     // Facebook flakiness
//     /graph\.facebook\.com/i,
//     // Facebook blocked
//     /connect\.facebook\.net\/en_US\/all\.js/i,
//     // Chrome extensions
//     /extensions\//i,
//     /^chrome:\/\//i,
//     // Other plugins
//   ],
// })

const isError = (error: unknown): error is Error => {
  return error instanceof Error || (error && typeof error === 'object' && 'message' in error)
}

declare type CodedError = {
  code: string
}

export const isCodedError = (error: unknown): error is CodedError => {
  return isError(error) && 'code' in error
}

export const handleError: HandleClientError = ({ error, event }) => {
  if (event?.url?.pathname == '_app/version.json') {
    console.warn('Failed to fetch version.json')
    return
  }

  if (isError(error)) {
    if (error.message.includes('Not found') || error.message.includes('not Found')) {
      return {
        status: 404,
        message: 'Not found',
        code: 'NotFound',
      }
    }

    console.error(error)

    // @ts-ignore
    if (import.meta.env.PROD) reportError(error, event)

    return {
      message: error.message ?? 'Internal Error',
      code: isCodedError(error) ? error.code : 'UNKNOWN',
      stack: error.stack,
    }
  }

  return {
    message: 'Internal Error',
  }
}
