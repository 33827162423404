import * as client_hooks from '../../src/hooks.client.ts';

export { matchers } from './client-matchers.js';

export const nodes = [() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29')];

export const server_loads = [];

export const dictionary = {
	"/(fullwidth)": [~28,[4]],
	"/(app)/@[username]": [~6,[2]],
	"/(app)/@[username]/stories/[slug]": [~7,[2]],
	"/(app)/blog": [~8,[2]],
	"/(app)/blog/[slug]": [~9,[2]],
	"/(standalone)/book/[slug]": [29,[5]],
	"/(app)/countries": [~10,[2]],
	"/(app)/countries/popular": [~11,[2]],
	"/(app)/flights/[solution]": [12,[2]],
	"/(app)/legal/licenses": [13,[2]],
	"/(app)/legal/privacy": [14,[2]],
	"/(app)/legal/terms": [15,[2]],
	"/(app)/profiles": [~16,[2]],
	"/(app)/stays": [~17,[2]],
	"/(app)/stays/[slug]": [~18,[2]],
	"/(app)/travel-restrictions": [19,[2,3]],
	"/(app)/travel-restrictions/[originAndVaccination=origin]": [~21,[2,3]],
	"/(app)/travel-restrictions/[destinationFromOriginAndVaccination=destination]": [~20,[2,3]],
	"/(app)/trips": [22,[2]],
	"/(app)/trips/pinned": [24,[2]],
	"/(app)/trips/[slug]": [23,[2]],
	"/(app)/visas": [~25,[2]],
	"/(app)/visas/evisas": [~27,[2]],
	"/(app)/visas/[slug]": [~26,[2]]
};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};